import * as React from 'react';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {ProductLineItemDataHook} from '../../ProductLineItemBase';
import {classes, st} from './Subscription.st.css';
import {useLocaleKeys} from '../../../../../LocaleKeysProvider';
import {SubscriptionFrequency, SubscriptionInfo} from '../../../../../../../types/app.types';
import {frequencyText, getSubscriptionDuration} from '../../../ProductLineItemsSection/utils';
import {Text} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../../ControllerContext';

export interface SubscriptionProps {
  itemId: number | string;
  subscription?: {name: string; duration: string};
  subscriptionInfo?: SubscriptionInfo;
  className?: string;
}

export const SubscriptionDataHooks = {
  Duration: 'SubscriptionDataHooks.Duration',
  Name: 'SubscriptionDataHooks.Name',
  StartDate: 'SubscriptionDataHooks.StartDate',
  FreeTrial: 'SubscriptionDataHooks.FreeTrial',
};

export const Subscription = ({itemId, subscription, subscriptionInfo, className}: SubscriptionProps) => {
  const localeKeys = useLocaleKeys();
  const {language} = useEnvironment();
  const {supportFreeTrialTYP, useNewSubscriptionView} = useControllerProps().thankYouPageStore;
  const startDate =
    useNewSubscriptionView && subscriptionInfo?.subscriptionSettings?.startDate
      ? new Date(Number(subscriptionInfo.subscriptionSettings.startDate)).toLocaleDateString(language)
      : undefined;
  const shouldShowFreeTrial =
    supportFreeTrialTYP &&
    subscriptionInfo?.subscriptionSettings?.freeTrialPeriod?.interval &&
    subscriptionInfo?.subscriptionSettings?.freeTrialPeriod?.frequency !== SubscriptionFrequency.UNDEFINED;

  return subscriptionInfo && useNewSubscriptionView ? (
    <div className={st(classes.root, className)} data-hook={ProductLineItemDataHook.Subscription}>
      {subscriptionInfo.subscriptionOptionTitle && (
        <Text className={classes.text} data-hook={SubscriptionDataHooks.Name}>
          {subscriptionInfo.subscriptionOptionTitle}
        </Text>
      )}
      <Text className={classes.text} data-hook={SubscriptionDataHooks.Duration}>
        {getSubscriptionDuration({
          subscription: subscriptionInfo,
          localeKeys,
        })}
      </Text>
      {startDate && (
        <Text className={classes.text} data-hook={SubscriptionDataHooks.StartDate}>
          {localeKeys.thankYouPage.pricingPlans.startDate.label({
            date: startDate,
          })}
        </Text>
      )}
      {shouldShowFreeTrial && (
        <Text className={classes.text} data-hook={SubscriptionDataHooks.FreeTrial}>
          {localeKeys.thankYouPage.pricingPlans.freeTrial.label({
            number: subscriptionInfo.subscriptionSettings.freeTrialPeriod.interval,
            frequency: frequencyText({
              interval: subscriptionInfo.subscriptionSettings.freeTrialPeriod.interval,
              frequency: subscriptionInfo.subscriptionSettings.freeTrialPeriod.frequency,
              localeKeys,
            }),
          })}
        </Text>
      )}
    </div>
  ) : (
    <div className={st(classes.root, className)} data-hook={ProductLineItemDataHook.Subscription}>
      <div key={`subscription-name-item-${itemId}-value-${subscription.name}`}>{subscription.name}</div>
      <div key={`subscription-duration-item-${itemId}-value-${subscription.duration}`}>{subscription.duration}</div>
    </div>
  );
};

Subscription.displayName = 'ProductLineItem.Subscription';
